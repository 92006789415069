export const isStripeConnected = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isStripeConnected
  );
};

export const isPaypalConnected = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.isPaypalConnected
  );
};

export const getShippingDetails = currentUser => {
  return (
    currentUser &&
    currentUser.id &&
    currentUser?.attributes?.profile?.protectedData?.shippingDetails
  );
};

export const getPaypalMerchantId = currentUser => {
  return (
    currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.paypalMerchantId
  );
};
