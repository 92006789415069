import React from 'react';
import css from './RippleLoader.module.css';

function RippleLoader() {
  return (
    <div className={css.ripple}>
      <div></div>
      <div></div>
    </div>
  );
}

export default RippleLoader;
